const steps = [
  {
    element: "#csv-add",
    popover: {
      title: "Add",
      description: "Click here to add a new stock",
      position: "bottom",
    },
  },
  {
    element: "#csv-search",
    popover: {
      title: "Search",
      description: "Click here to search for a stock",
      position: "bottom",
    },
  },
  {
    element: "#csv-table",
    popover: {
      title: "Table",
      description: "Click here to view the table of stocks",
      position: "bottom",
    },
  },
];
export default steps;
